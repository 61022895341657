/* @import "fonts.css"; */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
/* Chrome, Safari, Edge, Opera */
* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, sans-serif !important;
}

.input-hide-arrow::-webkit-outer-spin-button,
.input-hide-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-hide-arrow {
  -moz-appearance: textfield;
}

.chakra-select__wrapper:not(.chakra-select__wrapper:has(.keep-default)) {
  overflow: hidden;
}

.chakra-select__wrapper:not(.chakra-select__wrapper:has(.keep-default))
  .chakra-select__icon-wrapper {
  width: 40px;
  height: calc(100% - 2px);
  margin-right: -7px;
  border-radius: 0 5px 5px 0;
  background: #edf2f7;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .fluid_banner_background {
    background: url("/images/corporate-information/background_pattern_left.svg")
        70px top no-repeat,
      url("/images/corporate-information/background_pattern_right.svg") right
        bottom no-repeat,
      #294ba0 left top repeat;
  }
}

body:has(.print-resume) {
  position: relative;
  overflow: hidden;
}

.timeline {
  position: relative;
}

.timeline::after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 2px;
  background-image: linear-gradient(
    #3c64b1,
    rgba(181, 177, 177, 1),
    rgba(181, 177, 177, 0)
  );
  top: 0;
  bottom: 0;
  margin-top: 5px;
  margin-left: 15px;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container > input.no-border {
  border: none;
}
.react-datepicker-ignore-onclickoutside.no-border:focus-visible {
  border: none;
  box-shadow: none;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .text-black {
    color: #000;
  }
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

.box-shadow-md {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}
/* Hide scrollbar for Chrome, Safari and Opera */
/* div::-webkit-scrollbar {
  display: none;
} */
/* Hide scrollbar for IE, Edge and Firefox */
/* div {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} */

.swiper-button-next {
  color: #232f57 !important;
  right: 0 !important;
  width: 32px !important;
  height: 32px !important;
  padding: 8px !important;
  border-radius: 100% !important;
  background-color: #f5f7fa !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  &:after {
    font-size: 18px !important;
    font-weight: bolder !important;
  }
}

.swiper-button-prev {
  color: #232f57 !important;
  left: 0 !important;
  width: 32px !important;
  height: 32px !important;
  padding: 8px !important;
  border-radius: 100% !important;
  background-color: #f5f7fa !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  &:after {
    font-size: 18px !important;
    font-weight: bolder !important;
  }
}

.swiper-container {
  width: 320px;
}

@media screen and (min-width: 480px) {
  .swiper-container {
    width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

@media screen and (min-width: 992px) {
  .swiper-container {
    width: 992px;
  }
}

@media screen and (min-width: 1280px) {
  .swiper-container {
    width: 1280px;
  }
}

.custom-scrollbar {
  overflow: hidden;
}
